import { addNewDataSourceToTemplate } from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import IconButton from 'views/Common/Buttons/IconButton'
import FormSection from 'views/Common/Form/FormSection'
import ProfileAutoMarkersDetailsPanel from '../ParameterPanels/ProfileAutoMarkersDetails/ProfileAutoMarkersDetailsPanel'
import ProfileAutoSleepDetailsPanel from '../ParameterPanels/ProfileAutoSleepDetails/ProfileAutoSleepDetailsPanel'
import ProfileDataSourceDetailsPanel from '../ParameterPanels/ProfileDataSourceDetails/ProfileDataSourceDetailsPanel'
import ProfileMetricsConfigurationDetailsPanel from '../ParameterPanels/ProfileMetricsConfigurationDetails/ProfileMetricsConfigurationDetailsPanel'
import ProfileWorkDetailsPanel from '../ParameterPanels/ProfileWorkDetails/ProfileWorkDetailsPanel'
import ProfileWorkloadDetailsPanel from '../ParameterPanels/ProfileWorkloadDetails/ProfileWorkloadDetailsPanel'
import ProfileExpansionPanel from './ProfileExpansionPanel'

const ProfilesSelectorPanel = () => {
    const dispatch = useAppDispatch()
    const template = useAppSelector((rs) => rs.profile.template)

    return (
        <>
            <FormSection title="Profiles" padding={0}>
                <ProfileExpansionPanel type="Work">
                    <ProfileWorkDetailsPanel enableCommonFields={false} validatedForm={true} operationMode="Edit" />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Sleep">
                    <ProfileAutoSleepDetailsPanel
                        enableCommonFields={false}
                        validatedForm={true}
                        operationMode="Edit"
                    />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Markers">
                    <ProfileAutoMarkersDetailsPanel enableCommonFields={false} operationMode="Edit" />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Metrics">
                    <ProfileMetricsConfigurationDetailsPanel enableCommonFields={false} validatedForm={true} />
                </ProfileExpansionPanel>
                <ProfileExpansionPanel type="Workload">
                    <ProfileWorkloadDetailsPanel enableCommonFields={false} operationMode="Edit" />
                </ProfileExpansionPanel>
            </FormSection>
            <FormSection title="Data Sources" padding={0}>
                {template &&
                    template.profileDataSourceIds &&
                    template.profileDataSourceIds.length > 0 &&
                    template.profileDataSourceIds.map((profileId, index) => (
                        <ProfileExpansionPanel
                            dataSourceId={profileId}
                            key={index}
                            type="DataSource"
                            onDelete={(id: string) => {
                                dispatch(profileActions.removeDataSourceFromTemplate(id))
                            }}
                        >
                            <ProfileDataSourceDetailsPanel
                                id={profileId}
                                operationMode="Edit"
                                validatedForm={true}
                                enableCommonFields={false}
                            />
                        </ProfileExpansionPanel>
                    ))}
            </FormSection>
            <IconButton
                text="Add Data Source"
                tooltip="Add"
                icon="bi-file-plus"
                onClick={async () => {
                    await dispatch(addNewDataSourceToTemplate())
                }}
                style={{ marginTop: 10 }}
            />
        </>
    )
}

export default ProfilesSelectorPanel
