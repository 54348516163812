import React from 'react'
import { selectProfileByType } from 'store/actions/profileActions'
import { updateTemplateProfileId } from 'store/actions/templateActions'
import { useAppDispatch, useAppSelector } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { getProfileTitleByType, Profile } from 'types/ProfileInterfaces'
import IconButton from 'views/Common/Buttons/IconButton'
import TemplateProfileExpansionPanel from '../Templates/TemplateProfileExpansionPanel'
import ProfileSelectorTool from './ProfileSelectorTool'

interface Props {
    type: ProfileModuleTypes
    dataSourceId?: string
    onDelete?: (deleteItemId: string) => void
    children: React.ReactNode
}

const ProfileExpansionPanel = ({ type, dataSourceId, onDelete, children }: Props) => {
    const dispatch = useAppDispatch()
    const profile = useAppSelector((rs) => selectProfileByType(rs, type, dataSourceId)) as Profile | null

    const custom = Boolean(profile && profile.libraryId)

    // todo: onSave
    const onSave = () => {}

    const onReset = () => {
        if (profile && profile.libraryId) {
            dispatch(updateTemplateProfileId(profile.libraryId, profile.id, type))
        }
    }

    const selectorTool = (
        <ProfileSelectorTool
            profileType={type}
            dataSourceId={dataSourceId}
            onSave={onSave}
            onSelected={async (selectedProfileId, oldId) => {
                await dispatch(updateTemplateProfileId(selectedProfileId, oldId, type))
            }}
        />
    )

    const deleteButton = type === 'DataSource' && (
        <IconButton
            tooltip="Delete"
            toolbarLeftMargin
            icon="bi-trash"
            onClick={(e) => {
                e.stopPropagation()
                onDelete?.(dataSourceId || '')
            }}
        />
    )

    return (
        <TemplateProfileExpansionPanel
            title={getProfileTitleByType(type)}
            selectorTool={selectorTool}
            deleteButton={deleteButton}
            custom={custom}
            onSave={onSave}
            onReset={onReset}
        >
            {children}
        </TemplateProfileExpansionPanel>
    )
}

export default ProfileExpansionPanel
