import Axios from 'services/axios/axios-sfc'
import { NewScheduleDefaults, parseNewScheduleDefaults } from 'types/interfaces'
import { parseScenario, Scenario, ScenarioDetail } from 'types/Scenario'
import Schedule, { parseSchedule } from 'types/Schedule'
import SFApiBase from './sfApiBase'

class SFScenarioApi extends SFApiBase {
    constructor(private axios: Axios) {
        super()
    }

    getScenariosList = async (): Promise<ScenarioDetail[]> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Scenarios', this.getConfig())).data as any[]
        return data.map(parseScenario)
    }

    getScenariosWithMetrics = async (): Promise<Array<ScenarioDetail>> => {
        const data = (await this.axios.get(this.baseUrl + '/Api/Scenarios/ScenariosWithMetrics', this.getConfig()))
            .data as Array<any>
        return data.map<ScenarioDetail>(parseScenario)
    }

    getScenarioNamesList = async (): Promise<string[]> => {
        const scenarioNames = (await this.axios.get(this.baseUrl + '/Api/Scenarios/GetListForSaveAs', this.getConfig()))
            .data as string[]
        scenarioNames.sort()
        return scenarioNames
    }

    getScenarioSchedules = async (scenarioId: number): Promise<Array<Schedule>> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Scenarios/${scenarioId}/Schedules`, this.getConfig()))
            .data as Array<any>
        return data.map<Schedule>(parseSchedule)
    }

    deleteScenarios = async (scenarioIds: number[]): Promise<void> => {
        await this.axios.delete(this.baseUrl + '/Api/Scenarios', scenarioIds, this.getConfig())
    }

    duplicateScenario = async (scenarioId: number, name: string): Promise<void> => {
        await this.axios.post(
            this.baseUrl + '/Api/Scenarios/DuplicateScenario/',
            {
                scenarioId,
                name,
            },
            this.getConfig(),
        )
    }

    getNewScheduleDefaultsForScenario = async (scenarioId: number): Promise<NewScheduleDefaults> => {
        const data = (
            await this.axios.get(
                this.baseUrl + `/Api/Schedules/GetNewScheduleDefaultsForScenario/${scenarioId}`,
                this.getConfig(),
            )
        ).data as Array<any>
        return parseNewScheduleDefaults(data)
    }

    // todo: consolidate these methods
    // older api returning Scenario
    getScenarioById = async (scenarioId: number): Promise<ScenarioDetail> => {
        const data = (await this.axios.get(this.baseUrl + `/Api/Scenarios/Detail/${scenarioId}`, this.getConfig()))
            .data as any
        return parseScenario(data)
    }
    // new api returning ScenarioDetail
    getScenario = async (id: number): Promise<Scenario> => {
        return (await this.axios.get(this.baseUrl + `/Api/Scenarios/${id}`, this.getConfig())).data as Scenario
    }

    createScenario = async (scenario: Scenario) => {
        const formData = new FormData()
        formData.append('jsonData', JSON.stringify(scenario as Scenario))
        return (await this.axios.post(this.baseUrl + '/Api/Scenarios', formData, this.getConfig())).data as Scenario
    }

    updateScenario = async (scenario: Scenario) => {
        const formData = new FormData()
        formData.append('jsonData', JSON.stringify(scenario as Scenario))
        return (await this.axios.put(this.baseUrl + '/Api/Scenarios', formData, this.getConfig())).data as Scenario
    }

    // todo: consolidate these methods
    getDefaultScenario = async (): Promise<Scenario> => {
        return (await this.axios.get(this.baseUrl + '/Api/Scenarios/CreateEntity', this.getConfig())).data as Scenario
    }
    copyScenario = async (id: string): Promise<Scenario> => {
        const scenarioDetail = (
            await this.axios.get(this.baseUrl + `/Api/Scenarios/CreateEntity/${id}`, this.getConfig())
        ).data
        scenarioDetail.id = 0
        return scenarioDetail
    }
}

export default SFScenarioApi
