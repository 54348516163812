import useSessionStatus from 'hooks/useSessionStatus'
import useSettingValue from 'hooks/useSettingValue'
import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SettingConsts } from 'types/SystemSetting'
import NotFound from 'views/Common/NotFound/NotFound'
import FileProcessingPage from 'views/FileProcessing/Page/FileProcessingPage'
import HazardClassConfiguration from 'views/Insights/Page/HazardClassConfiguration'
import HazardClassView from 'views/Insights/Page/HazardClassView'
import InsightsDashboard from 'views/Insights/Page/InsightsDashboard'
import Logout from 'views/Logout/Logout'
import ReportConfiguration from 'views/Reports/Page/ReportConfiguration'
import ReportsDashboard from 'views/Reports/Page/ReportsDashboard'
import ReportView from 'views/Reports/Page/ReportView'
import ResetPassword from 'views/ResetPassword/ResetPassword'
import ScenarioDetailPage from 'views/Scenarios/Page/ScenarioDetailPage'
import ScenarioRedirectPage from 'views/Scenarios/Page/ScenarioRedirectPage'
import ScenariosPage from 'views/Scenarios/Page/ScenariosPage'
import ScheduleDetailsPage from 'views/Schedules/ScheduleDetails/Page/ScheduleDetailsPage'
import SchedulesPage from 'views/Schedules/SchedulesPage/SchedulesPage'
import SettingsLayout from 'views/Settings/Components/SettingsLayout'
import ProfileAutoMarkersDetailsPanel from 'views/Settings/ParameterPanels/ProfileAutoMarkersDetails/ProfileAutoMarkersDetailsPanel'
import ProfileAutoSleepDetailsPanel from 'views/Settings/ParameterPanels/ProfileAutoSleepDetails/ProfileAutoSleepDetailsPanel'
import ProfileDataSourceDetailsPanel from 'views/Settings/ParameterPanels/ProfileDataSourceDetails/ProfileDataSourceDetailsPanel'
import ProfileMetricsConfigurationDetailsPanel from 'views/Settings/ParameterPanels/ProfileMetricsConfigurationDetails/ProfileMetricsConfigurationDetailsPanel'
import ProfileWorkDetailsPanel from 'views/Settings/ParameterPanels/ProfileWorkDetails/ProfileWorkDetailsPanel'
import ProfileWorkloadDetailsPanel from 'views/Settings/ParameterPanels/ProfileWorkloadDetails/ProfileWorkloadDetailsPanel'
import ProfileDetailsPage from 'views/Settings/Profiles/ProfileDetailPage'
import TemplateDetailPage from 'views/Settings/Templates/TemplateDetailPage'

const AuthenticatedRoutes = (props: { logoutHandler: () => void }) => {
    const userState = useSessionStatus()
    const enableFeatureReports = useSettingValue<boolean>(SettingConsts.general.features_Reporting)
    const enableFeatureInsights = useSettingValue<boolean>(SettingConsts.general.features_Insights)
    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route path="/scenarios" element={<ScenariosPage />} />
                <Route path="/scenario/:id/:operation?" element={<ScenarioDetailPage />} />
                <Route path="/scenario/:id/schedules" element={<SchedulesPage />} />
                <Route path="/schedule/:id" element={<ScheduleDetailsPage />} />
                <Route path="/schedules/" element={<ScenarioRedirectPage />} />
                <Route path="/reports/:id/configuration" element={<ReportConfiguration />} />
                <Route path="/reports/:id/configurationFromDashboard" element={<ReportConfiguration fromDashboard />} />
                <Route path="/reports-print/:id" element={<ReportView isPrintPreview />} />
                <Route path="/reports-print" element={<ReportsDashboard isPrintPreview />} />
                <Route path="/reports/newreport" element={<ReportConfiguration />} />
                <Route path="/reports/:id" element={<ReportView />} />
                {enableFeatureReports && <Route path="/reports/" element={<ReportsDashboard />} />}
                <Route path="/insights/:id/configuration" element={<HazardClassConfiguration />} />
                <Route
                    path="/insights/:id/configurationFromDashboard"
                    element={<HazardClassConfiguration fromDashboard />}
                />
                <Route
                    path="/insights/:id/copyFromDashboard"
                    element={<HazardClassConfiguration isCopy fromDashboard />}
                />
                <Route path="/insights/:id/copy" element={<HazardClassConfiguration isCopy />} />
                <Route path="/insights/NewHazardClass" element={<HazardClassConfiguration />} />
                <Route path="/insights/:id" element={<HazardClassView />} />
                {enableFeatureInsights && <Route path="/insights/" element={<InsightsDashboard />} />}
                <Route path="/fileprocessing" element={<FileProcessingPage />} />
                <Route path="/" element={<Navigate to="/scenarios" replace />} />
                <Route
                    path="/logout"
                    element={
                        userState === 'LoggedIn' ? (
                            <Logout logoutHandler={props.logoutHandler} />
                        ) : (
                            <Navigate to="/" replace />
                        )
                    }
                />

                <Route
                    path="/setpasswordrequest"
                    element={<ResetPassword mode="RequestReset" logout={props.logoutHandler} />}
                />
                <Route
                    path="/setpassword/:token"
                    element={<ResetPassword mode="SetNewPassword" logout={props.logoutHandler} />}
                />
                <Route path="/settings" element={<SettingsLayout />} />
                <Route path="/settings/palettes" element={<SettingsLayout />} />
                <Route path="/settings/profiles" element={<SettingsLayout />} />
                <Route path="/settings/profiles/work" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/work/:id/:operation?"
                    element={<ProfileDetailsPage type="Work" detailsPanel={ProfileWorkDetailsPanel} />}
                />
                <Route path="/settings/profiles/sleep" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/sleep/:id/:operation?"
                    element={<ProfileDetailsPage type="Sleep" detailsPanel={ProfileAutoSleepDetailsPanel} />}
                />
                <Route path="/settings/profiles/metrics" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/metrics/:id/:operation?"
                    element={
                        <ProfileDetailsPage type="Metrics" detailsPanel={ProfileMetricsConfigurationDetailsPanel} />
                    }
                />
                <Route path="/settings/profiles/workload" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/workload/:id/:operation?"
                    element={<ProfileDetailsPage type="Workload" detailsPanel={ProfileWorkloadDetailsPanel} />}
                />
                <Route path="/settings/profiles/markers" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/markers/:id/:operation?"
                    element={<ProfileDetailsPage type="Markers" detailsPanel={ProfileAutoMarkersDetailsPanel} />}
                />
                <Route path="/settings/profiles/datasource" element={<SettingsLayout />} />
                <Route
                    path="/settings/profiles/datasource/:id/:operation?"
                    element={<ProfileDetailsPage type="DataSource" detailsPanel={ProfileDataSourceDetailsPanel} />}
                />
                <Route path="/settings/stations" element={<SettingsLayout />} />
                <Route path="/settings/users" element={<SettingsLayout />} />
                <Route path="/settings/templates" element={<SettingsLayout />} />
                <Route path="/settings/templates/:id/:operation?" element={<TemplateDetailPage />} />
                <Route path="/editscenario/:id/:operation?" element={<ScenarioDetailPage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    )
}

export default AuthenticatedRoutes
