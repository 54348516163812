import { TagKeyValue } from 'types/ScheduleEvent'
import IconButtonAdd from 'views/Common/Buttons/IconButtonAdd'
import FormLabelCustom from 'views/Common/Widget/FormLabelCustom'
import TagsTable from 'views/Common/Widget/TagsTable'

interface TagsEditorProps {
    tags: TagKeyValue[]
    allTagNames: string[]
    tagRemoved: (tagId: number) => void
    tagChanged: (tagId: number, name: string, value: string) => void
    onClickAddTag?: () => void
}

const TagsEditor = ({ tags, allTagNames, tagRemoved, tagChanged, onClickAddTag }: TagsEditorProps) => {
    return (
        <>
            <div>
                <FormLabelCustom>Tags</FormLabelCustom>
            </div>
            <TagsTable tags={tags} allTagNames={allTagNames} tagRemoved={tagRemoved} tagChanged={tagChanged} />
            <IconButtonAdd style={{ marginTop: 10 }} onClick={onClickAddTag} />
        </>
    )
}

export default TagsEditor
