import {
    AutoSleepModeTypes,
    BaseDeterminationOnImportTypes,
    CommuteTypes,
    CrewingTypes,
    CriticalTimeTypes,
    DurationUnitTypes,
    DutyBreakTypes,
    EventTriggerReferenceTypes,
    EventTypeCreationTypes,
    FilterTypes,
    IndustryTypes,
    InputTypes,
    OffsetUnitTypes,
    OverlapTypes,
    PriorWorkTypes,
    ProfileModuleTypes,
    RosterStageTypes,
    ScheduleTypes,
    SleepQualityTypes,
    SleepRuleDurationTypes,
    SplitDutyLocationTypes,
    WeightTypes,
    WorkloadFactorTypes,
    ZoneTypes,
} from './EnumTypes'
import { QueryBuilderValue } from './Reports'

export const getProfileTitleByType = (type: ProfileModuleTypes): string => {
    switch (type) {
        case 'Work':
            return 'Work'
        case 'Sleep':
            return 'Auto-Sleep'
        case 'Markers':
            return 'Auto-Markers'
        case 'Metrics':
            return 'Metrics Configuration'
        case 'Workload':
            return 'Workload'
        case 'DataSource':
            return 'Data Source'
        default:
            return 'Unknown Profile'
    }
}

// Profiles
export interface Profile {
    id: string
    libraryId?: string
    name: string
    description: string
    profileType: ProfileModuleTypes
    templatesUsing?: number
    scenariosUsing?: number
    owner?: string
}

export interface ProfileDataSource extends Profile {
    dataSourceType: InputTypes
    determineBaseStationFrom: BaseDeterminationOnImportTypes
    combineSchedulesWithOperatingDates: boolean
    combineEventsIntoDutiesWithin: number
    labelFilterType: string
    labelFilterList: string
    durationFilterEnable: boolean
    durationFilterRangeStart: number
    durationFilterRangeEnd: number
    eventTypeCreationType: EventTypeCreationTypes
    eventTypeCreationList: string
    complementCodes: string
    dateStartFilterEnable: boolean
    dateStartFilter: Date
    dateEndFilterEnable: boolean
    dateEndFilter: Date
    baseFilterType: FilterTypes
    baseFilterList: string
    locationFilterType: FilterTypes
    locationFilterList: string
    equipmentFilterType: FilterTypes
    equipmentFilterList: string
    dataFieldPath1: string
    dataFieldPath2: string
    customDateFormat: string
    crewingCodes: string
    ignorePlannedWorkSleepRules: boolean
    timeReference: ZoneTypes
    rosterPlanningStage: RosterStageTypes
    periodStart: Date
    scheduleType: ScheduleTypes
    scheduleYear: number
    fleet: string
    hubTurnsMaximumGap: number
    hubTurnsLocations: string
    timezoneOffset: number
}

export interface ProfileWork extends Profile {
    autoDuties?: boolean
    defaultBaseStation?: string
    industry: IndustryTypes
    briefDuration?: number
    debriefDuration?: number
    dutyBreakType: DutyBreakTypes
    minBreakTimeAtHotel?: number
    minBreakTimeAtRestFacility?: number
    sleepPreference: SplitDutyLocationTypes
    sleepDuringStart?: Date
    sleepDuringEnd: Date
    minSleepDuringBreak?: number
    bufferType: CommuteTypes
    prepTimeAtHome: number
    prepTimeAtHotel: number
    prepTimeAtRestFacility: number
    commuteTimeAtHome?: number
    commuteTimeAtHotel?: number
    commuteTimeAtRestFacility?: number
    unwindTimeAtHome?: number
    unwindTimeAtHotel?: number
    unwindTimeAtRestFacility?: number
    bufferDuration?: number
    eventDurationRules: EventDurationRules[]
}

export interface ProfileAutoSleep extends Profile {
    normalBedtime: Date
    minSleepDuration: number
    awakeZoneStart: Date
    awakeZoneEnd: Date
    sleepQualityHome: SleepQualityTypes
    sleepQualityHotel: SleepQualityTypes
    sleepQualityRestFacility: SleepQualityTypes
    sleepQualityRules: SleepQualityRules[]
    transmeridianSleepMode: AutoSleepModeTypes
    explicitSleepBufferBefore: number
    explicitSleepBufferAfter: number
    explicitSleepBufferMax: number
    regularSleepEnable: boolean
    maxWorkDaySleep: number
    maxRestDaySleep: number
    advancedBedtimeEnable: boolean
    postWorkSleepEnable: boolean
    maxRecoverySleep: number
    preWorkSleepEnable: boolean
    napRules: SleepNapRule[]
    napSleepQuality: SleepQualityTypes
    plannedWorkSleepEnable: boolean
    sleepRules: SleepRules[]
    eventRules: EventRules[]
}

export interface ProfileAutoMarker extends Profile {
    autoMarkerRules: AutoMarkerRules[]
}

export interface ProfileMetricsConfiguration extends Profile {
    effectivenessCriterionLevel: number
    belowCriterionThreshold: number
    criticalTimeDetermination: CriticalTimeTypes
    dutyDurationBrief: boolean
    dutyDurationDebrief: boolean
    wocl: TimeRanges
    night: TimeRanges
    redeye: TimeRanges
    daySleep: TimeRanges
    earlyStart: TimeRanges
    lateEnd: TimeRanges
}

export interface ProfileWorkload extends Profile {
    workloadRules: WorkloadRule[]
    workloadFactors: WorkloadFactor[]
    scaleMaximum?: number
    decayPeriod?: number
}

// Event Filter

export interface EventFilter {
    id: string
    name: string
    description?: string
    eventQuery?: QueryBuilderValue
    crewingEnable: boolean
    crewingType: CrewingTypes
    durationEnable: boolean
    durationFrom: number
    durationTo: number
    locationEnable: boolean
    locationCode: string
    routeEnable: boolean
    routeFrom: string
    routeTo: string
    eventsEnable: boolean
    events: string
    equipmentTypesEnable: boolean
    equipmentTypes: string
    crewComplementEnable: boolean
    crewComplementFrom: number
    crewComplementTo: number
    crewPositionsEnable: boolean
    crewPositions: string
    timeOfDayEnable: boolean
    timeOfDayFrom: Date
    timeOfDayTo: Date
    timeOfDayZoneType: ZoneTypes
    overlapType: OverlapTypes
}

// Rules

export interface EventDurationRules {
    id: string
    eventFilter: EventFilter
    briefHome: number
    briefHotel: number
    briefRestFacility: number
    debriefHome: number
    debriefHotel: number
    debriefRestFacility: number
    commuteHome: number
    commuteHotel: number
    commuteRestFacility: number
    prepHome: number
    prepHotel: number
    prepRestFacility: number
    unwindHome: number
    unwindHotel: number
    unwindRestFacility: number
    order: number
}

export interface SleepQualityRules {
    id: string
    eventFilter: EventFilter
    order: number
    home: SleepQualityTypes
    hotel: SleepQualityTypes
    restFacility: SleepQualityTypes
}

export interface SleepNapRule {
    id: string
    priorRestMin: number
    priorRestMax: number
    napDuration: number
    priorWorkType: PriorWorkTypes
    order: number
}

export interface SleepRules {
    id: string
    name: string
    order: number
    durationFixed: number
    durationS: number
    durationE: number
    durationN: number
    durationP: number
    durationType: SleepRuleDurationTypes
    quality: SleepQualityTypes
    sleepPeriods: SleepPeriods[]
}

export interface SleepPeriods {
    id: string
    position: number
    percentOfSleep: number
}

export interface EventRules {
    id: string
    order: number
    sleepRuleId: string
    eventFilter: EventFilter
}

export interface AutoMarkerRules {
    id: string
    eventFilter: EventFilter
    eventOffset: number
    eventOffsetUnit: OffsetUnitTypes
    eventOffsetReference: EventTriggerReferenceTypes
    markerName: string
    markerDuration: number
    markerDurationUnit: DurationUnitTypes
    markerIsCritical: boolean
    order: number
}

export interface WorkloadFactor {
    id: string
    workloadFactorType: WorkloadFactorTypes
    name: string
    weight: WeightTypes
    order: number
}

export interface WorkloadRule {
    id: string
    eventFilter: EventFilter
    triggerOnEventStart: boolean
    triggerInEventPeriodicallyDuration: number
    triggerOnEventEnd: boolean
    workloadFactors: WorkloadRuleFactor[]
    order: number
    [key: string]: any
}

export interface WorkloadRuleFactor {
    worloadFactorId: string
    factorName: string
    workloadFactorType: WorkloadFactorTypes
    enabled: boolean
}

// Types

export interface TimeRanges {
    start: Date
    end: Date
    timeReference: ZoneTypes
}

export type ProfileTypesWithoutDataSource = Exclude<ProfileModuleTypes, 'DataSource'>

export const getTemplateIdFieldForProfileType = (profileType: ProfileTypesWithoutDataSource): string => {
    return ProfileTypeToTemplateField[profileType]
}

export const ProfileTypeToTemplateField: Record<ProfileTypesWithoutDataSource, string> = {
    Work: 'profileWorkId',
    Sleep: 'profileAutoSleepId',
    Markers: 'profileAutoMarkerId',
    Metrics: 'profileMetricsConfigurationId',
    Workload: 'profileWorkloadId',
}
