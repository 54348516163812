import { AnyAction, Dispatch, ThunkAction } from '@reduxjs/toolkit'
import globals from 'services/global/globals'
import { profileActions } from 'store/profileStore'
import { RootState } from 'store/store'
import { ProfileModuleTypes } from 'types/EnumTypes'
import { Profile } from 'types/ProfileInterfaces'

type ProfileTypeStateProperty =
    | 'profileWork'
    | 'profileAutoSleep'
    | 'profileMetricsConfigurations'
    | 'profileWorkload'
    | 'profileAutoMarkers'
    | 'profileDataSources'

const getProfileStatePropertyByType = (type: ProfileModuleTypes): ProfileTypeStateProperty => {
    switch (type) {
        case 'Work':
            return 'profileWork'
        case 'Sleep':
            return 'profileAutoSleep'
        case 'Markers':
            return 'profileAutoMarkers'
        case 'Metrics':
            return 'profileMetricsConfigurations'
        case 'Workload':
            return 'profileWorkload'
        case 'DataSource':
            return 'profileDataSources'
        default:
            throw new Error(`Invalid ProfileModuleType: ${type}`)
    }
}

export interface FetchAndSetProfileByTypeArgs {
    id?: string
    type: ProfileModuleTypes
    forceReset?: boolean
}

const fetchAndSetProfileByType = ({
    id,
    type,
    forceReset: forceClear,
}: FetchAndSetProfileByTypeArgs): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        let profile: Profile | null = null
        if (!forceClear) {
            if (type === 'DataSource') {
                // datasource has a special organization in redux (array of objects)
                profile = getState().profile.profileDataSources.find((i) => i.profileDataSource.id === id)
                    ?.profileDataSource as Profile
            } else {
                const stateProperty = getProfileStatePropertyByType(type)
                profile = getState().profile[stateProperty] as Profile
            }

            if (profile && profile.id === id && !getProfileModifiedByType(getState(), type)) {
                // already in redux and not modified, nothing more to do
                return
            }
        }

        const api = globals.getApi().getProfileApi()
        if (id === 'new') {
            profile = await api.getDefaultProfile(type)
            profile.id = 'new'
        } else if (id) {
            profile = await api.getProfile(id, type)
        } else {
            profile = null
        }

        // store in redux
        dispatch(profileActions.setProfileByType({ profile, type }))
    }
}

const selectProfileByType = (state: RootState, type: ProfileModuleTypes, id?: string) => {
    if (type === 'DataSource') {
        const profileDatasource = state.profile.profileDataSources.find((i) => i.profileDataSource.id === id)
        return profileDatasource ? profileDatasource.profileDataSource : null
    }
    const stateProperty = getProfileStatePropertyByType(type)
    return state.profile[stateProperty]
}

const setProfileFromApiCopy = (
    id: string,
    type: ProfileModuleTypes,
): ThunkAction<void, RootState, undefined, AnyAction> => {
    return async (dispatch: Dispatch) => {
        const profileCopy = await globals.getApi().getProfileApi().copyProfile(id, type)
        profileCopy.id = 'new'
        dispatch(profileActions.setProfileByType({ profile: profileCopy, type }))
    }
}

const getProfileModifiedByType = (state: RootState, type: ProfileModuleTypes, id?: string): boolean => {
    switch (type) {
        case 'Work':
            return state.profile.profileWorkModified
        case 'Sleep':
            return state.profile.profileAutoSleepModified
        case 'Markers':
            return state.profile.profileAutoMarkersModified
        case 'Metrics':
            return state.profile.profileMetricsConfigurationsModified
        case 'Workload':
            return state.profile.profileWorkloadModified
        case 'DataSource': {
            if (!id) {
                return false
            }
            const profileState = state.profile.profileDataSources.find((i) => i.profileDataSource.id === id)
            return profileState ? profileState.modified : false
        }
        default:
            throw new Error(`Invalid ProfileModuleType: ${type}`)
    }
}

export { setProfileFromApiCopy, fetchAndSetProfileByType, selectProfileByType, getProfileModifiedByType }
