import React, { useState } from 'react'
import IconButton from 'views/Common/Buttons/IconButton'

interface TemplateProfileExpansionPanelProps {
    title?: string
    children: React.ReactNode
    selectorTool?: React.ReactNode
    deleteButton?: React.ReactNode
    custom?: boolean
    onSave?: () => void
    onReset?: () => void
}

const TemplateProfileExpansionPanel = ({
    title,
    children,
    selectorTool,
    deleteButton,
    custom,
    onSave,
    onReset,
}: TemplateProfileExpansionPanelProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const togglePanel = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div style={{ border: '1px solid #ccc', width: '100%' }}>
            <div
                onClick={togglePanel}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    backgroundColor: '#f7f7f7',
                    cursor: 'pointer',
                }}
            >
                {/* chevron and title */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i
                        className={isOpen ? 'bi-chevron-down' : 'bi-chevron-right'}
                        style={{
                            fontSize: '19px',
                            marginRight: '10px',
                        }}
                    />
                    {title && <h6 style={{ width: '160px', margin: 0 }}>{title}</h6>}
                </div>

                {/* selector and delete */}
                <div
                    style={{ flexGrow: 1, textAlign: 'center', marginLeft: '50px' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {selectorTool}
                </div>
                {deleteButton && <div style={{ marginRight: '10px' }}>{deleteButton}</div>}

                {/* customize buttons */}
                <div
                    style={{
                        width: '200px',
                        textAlign: 'right',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    {custom && (
                        <>
                            <span>CUSTOM</span>
                            <IconButton
                                tooltip="Customized - Revert to original"
                                toolbarLeftMargin
                                icon="bi-arrow-counterclockwise"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onReset?.()
                                }}
                            />
                            <IconButton
                                tooltip="Customized Profile - Save to Library"
                                toolbarLeftMargin
                                icon="bi-save"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onSave?.()
                                }}
                            />
                        </>
                    )}
                </div>
            </div>

            {/* Expandable Content */}
            {isOpen && <div style={{ padding: '10px', backgroundColor: '#fff' }}>{children}</div>}
        </div>
    )
}

export default TemplateProfileExpansionPanel
